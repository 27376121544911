import { fetchApi } from "@/utils/axios";
import URL from "@/api/urlConfig"

/**
 * 获取限时活动列表
 */
export const getLimitedTimeCouponList =  (data) => {
  return fetchApi(URL.GET_LIMITED_TIME_COUPON_LIST, data, 'POST', 'data')
}

/**
 * 新增限时活动
 */
export const insertLimitedTimeCoupon = (data) => {
  return fetchApi(URL.INSERT_LIMITED_TIME_COUPON, data, 'POST', 'data')
}

/**
 * 修改限时活动
 */
export const updateLimitedTimeCoupon = (data) => {
  return fetchApi(URL.UPDATE_LIMITED_TIME_COUPON, data, 'POST', 'data')
}

/**
 * 查询一条限时活动
 */
export const findOneLimitedTimeCouponById = (id) => {
  return fetchApi(URL.GET_LIMITED_TIME_COUPON_BY_ID + `/${id}` )
}

/**
 * 查询端点活动券信息
 */
export const findCouponInfoById = (id) => {
  return fetchApi(URL.GET_COUPON_INFO_BY_ID + `/${id}`)
}

/**
 * 禁用、启用
 */
export const toDisableEnable = (id, status) => {
  return fetchApi(URL.TO_DISABLE_ENABLE_LIMITED_TIME_COUPON + `/${id}/${status}`, '', 'post', 'data')
}

/**
 * 删除
 */
export const deleteLimitedTimeCouponById = (id) => {
  return fetchApi(URL.DELETE_LIMITED_tIME_COUPON_BY_ID + `/${id}`, '', 'post', 'data')
}
