<template>
<div>
  <a-form-model
    ref="ruleForm"
    :model="form"
    :rules="rules"
    :label-col="labelCol"
    :wrapper-col="wrapperCol"
  >
    <a-form-model-item ref="name" label="活动名称" prop="name">
      <a-input
        v-model="form.name"
        :disabled="isDisabled"
        placeholder="请输入活动名称"
        @blur="
          () => {
            $refs.name.onFieldBlur();
          }
        "
      />
    </a-form-model-item>
    <a-form-model-item ref="type" label="活动方式" prop="type">
      <a-radio-group :disabled="isDisabled" @change="typeHandleChange" v-model="form.type">
        <a-radio :value="1">
          免费
        </a-radio>
        <a-radio :value="2">
          积分兑换
        </a-radio>
      </a-radio-group>
      <a-input-number
        placeholder="消耗积分值"
        style="width: 100px"
        :disabled="disabledIntegralValue || isDisabled"
        :parser="(value) => value.replace('.', '')"
        :min="0" :max="999999"
        v-model="form.integralValue"
      />
    </a-form-model-item>
    <a-form-model-item ref="picUrl" label="活动图片" prop="picUrl" :wrapper-col="{ span: 14, offset: 0 }">
      <a-upload
        :disabled="isDisabled"
        :action="IMG_API + '/oss/files'"
        list-type="picture-card"
        :file-list="imgFileList"
        :beforeUpload="beforeUploadImg"
        :showUploadList="{showPreviewIcon: false}"
        @change="handleChangeImg"
      >
        <div v-if="imgFileList.length < 1">
          <a-icon type="plus" />
          <div class="ant-upload-text">
            上传
          </div>
        </div>
      </a-upload>
      <span style="color:red">图片类型支持jpg/png类型，图片尺寸为1:1，最大不能超过1MB</span>
    </a-form-model-item>
    <a-form-model-item ref="content" style="z-index: 0" label="活动说明" prop="content" :wrapper-col="{ span: 14, offset: 0 }">
      <div id="editer"></div>
    </a-form-model-item>
    <a-form-model-item ref="activityId" label="活动关联券ID" prop="activityId">
      <a-input
        v-model="form.activityId"
        :disabled="isDisabled"
        @change="activityIdHandleChange"
        @blur="
          () => {
            $refs.activityId.onFieldBlur();
          }
        "
      />
    </a-form-model-item>
    <div>
      <a-form-model-item label="总数量">
        {{ couponInfo.total }}
      </a-form-model-item>
      <a-form-model-item label="领取时间">
        {{ couponInfo.time }}
      </a-form-model-item>
      <a-form-model-item label="每人限领取张数">
        {{ couponInfo.limitedNum }}
      </a-form-model-item>
    </div>
    <a-form-model-item ref="activityTime" label="活动关联时间" prop="activityTime">
      <a-range-picker
        :disabled="isDisabled"
        :show-time="{ format: 'HH:mm' }"
        format="YYYY-MM-DD HH:mm:ss"
        :placeholder="['开始时间', '结束时间']"
        v-model="form.activityTime"
        @change="activityTimeHandleChange"
      />
    </a-form-model-item>
    <a-form-model-item label="活动项目" ref="projectIds" prop="projectIds">
      <a-select :disabled="isDisabled" placeholder="请选择活动项目" mode="multiple" v-model="form.projectIds">
        <a-select-option v-for="item in projectList" :key="item.id">
          {{ item.project_name }}
        </a-select-option>
      </a-select>
    </a-form-model-item>
    <a-form-model-item label="活动人群" ref="layerIds" prop="layerIds">
      <a-select :disabled="isDisabled" placeholder="请选择活动人群" mode="multiple" v-model="form.layerIds">
        <a-select-option v-for="item in allCrowds" :key="item.id">
          {{ item.name }}
        </a-select-option>
      </a-select>
    </a-form-model-item>
    <a-form-model-item label="活动状态" ref="status" :required="true">
      <a-radio-group :disabled="isDisabled" name="radioGroup" v-model="form.status" :default-value="1">
        <a-radio :value="1">
          启用
        </a-radio>
        <a-radio :value="0">
          禁用
        </a-radio>
      </a-radio-group>
    </a-form-model-item>
    <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
      <a-button v-if="!isDisabled" type="primary" @click="onSubmit">
        保存
      </a-button>
      <a-button style="margin-left: 10px;" @click="goBack">
        {{ `${isDisabled ? '返回' : '取消'}` }}
      </a-button>
    </a-form-model-item>
  </a-form-model>
</div>
</template>

<script>
import {IMG_API} from "@/config";
import wangEditor from "wangeditor";
import {projectName} from "@/api/modularManagement";
import { findAllCrowd } from '@/api/equityManagement'
import { insertLimitedTimeCoupon, findOneLimitedTimeCouponById, findCouponInfoById, updateLimitedTimeCoupon } from '@/api/limitedTimeCouponManagement'
import moment from "moment";
export default {
  name: "index",
  data() {
    let checkType = (rule, value, callback) => {
      if (!value) {
        callback(new Error('请选择活动方式'));
      } else if (value === 2 && !this.form.integralValue) {
        callback(new Error('请输入消耗积分值'));
      } else {
        callback();
      }
    };
    return {
      IMG_API: IMG_API,
      labelCol: { span: 4 },
      wrapperCol: { span: 5 },
      isDisabled: false,
      imgFileList:[],
      other: '',
      form: {
        name: '',
        type: 1,
        picUrl: '',
        content: '',
        activityId: '',
        activityTime: undefined,
        projectIds: [],
        layerIds: [],
        status: 1,
        // 消耗的积分值
        integralValue: '',
      },
      couponInfo: {
        total: 0,
        time: '',
        limitedNum: 0,
        receiveCouponTime: []
      },
      projectList: [],
      // 所有人群
      allCrowds: '',
      rules: {
        name: [
          { required: true, message: '请填写活动名称', trigger: 'blur' },
          { max: 20, message: '活动名称不能超过20字', trigger: 'blur' },
        ],
        type: [
          { validator: checkType, trigger: 'change, blur' }
        ],
        picUrl: [
          {required: true, message: '请上传活动图片', trigger: 'change'}
        ],
        activityId: [
          {required: true, message: '请填写活动关联券ID', trigger: 'blur'}
        ],
        activityTime: [
          {required: true, message: '请选择活动关联时间', trigger: 'change'}
        ],
        projectIds: [
          {required: true, message: '请选择活动项目', trigger: 'change'}
        ],
        layerIds: [
          {required: true, message: '请选择活动活动人群', trigger: 'change'}
        ],
        content: [
          {required: true, message: '请填写活动说明', trigger: 'change'}
        ]
      },
    };
  },
  computed: {
    disabledIntegralValue() {
      return !this.form.integralValue && this.form.type === 1
    }
  },
  created() {
    // 获取所有项目
    this.getProjectList()
    // 获取所有人群信息
    this.getAllCrowds();
    if (this.$route.query.id) {
        this.findOne(this.$route.query.id)
    }
    if (this.$route.query.type === 'view') {
      this.isDisabled = true
    }
  },
  methods: {
    async getProjectList() {
      const res = await projectName()
      const { code, data: {items}} = res
      if(code === '200') {
        this.projectList = items
      }
    },
    async getAllCrowds () {
      const res = await findAllCrowd()
      const { code, data } = res
      if (code === '200') {
        this.allCrowds = data
      }
    },
    async findOne(id) {
      const res = await findOneLimitedTimeCouponById(id);
      const { data: {couponInfo}, code, msg } = res
      if (code === '200') {
        let formData = couponInfo
        formData.projectIds = formData.projectIds.split(',')
        formData.layerIds = formData.layerIds.split(',')
        formData.activityTime = [moment(parseInt(formData.startTime)).format('YYYY-MM-DD HH:mm:ss'),
          moment(parseInt(formData.endTime)).format('YYYY-MM-DD HH:mm:ss')]
        this.form = formData
        this.imgFileList = [{
          uid: '-1',
          url: formData.picUrl,
        }]
        await this.findCouponInfo(this.form.activityId)
      } else {
        this.$message.error(msg)
      }
    },
    // 活动id change事件
    activityIdHandleChange() {
      // 查询券活动信息
      this.findCouponInfo(this.form.activityId)
    },
    async findCouponInfo(id) {
     const res = await findCouponInfoById(id);
     const { data, code } = res
      if ( code === '200') {
        if (data.status !== 1) {
          this.$message.warn('优惠券活动状态必须是进行中状态！')
          return false;
        }
        this.couponInfo.total = data.total
        this.couponInfo.time = `${moment(parseInt(data.warmStartAt)).format('YYYY-MM-DD HH:mm:ss')}
        - ${moment(parseInt(data.warmEndAt)).format('YYYY-MM-DD HH:mm:ss')}`
        this.couponInfo.limitedNum = data.everyoneLimit
        this.couponInfo.receiveCouponTime = [moment(parseInt(data.warmStartAt)).format('YYYY-MM-DD HH:mm:ss'),
          moment(parseInt(data.warmEndAt)).format('YYYY-MM-DD HH:mm:ss')]
      }
    },
    // 活动关联时间的change事件
    activityTimeHandleChange () {
      if (!(this.couponInfo && this.couponInfo.time)) {
        this.$message.warn("请先确认活动关联券ID")
        this.form.activityTime = undefined
        return
      }
    },
    async onSubmit() {
      this.$refs.ruleForm.validate(async valid => {
        if (valid) {
          try {
            // 校验券信息是否正确
            if (!this.checkCouponInfo()) {
              this.$message.error("请检查活动关联券ID是否正确！")
              throw new Error()
            }
            // 校验活动关联时间是否在当前活动券的领取范围内
            if (!this.checkActivityTime()) {
              this.$message.error("活动关联时间要包含领取时间！")
              throw new Error()
            }
            let data = JSON.parse(JSON.stringify(this.form));
            data.startTime = moment(data.activityTime[0]).format('YYYY-MM-DD HH:mm:ss')
            data.endTime = moment(data.activityTime[1]).format('YYYY-MM-DD HH:mm:ss')
            data.projectIds = data.projectIds.toString()
            data.layerIds = data.layerIds.toString()
            let res = {};
            if (this.$route.query.type === 'add') {
              res = await insertLimitedTimeCoupon(data)
            } else {
              res = await updateLimitedTimeCoupon(data)
            }
            const { code, msg } = res
            if (code === '200') {
              this.$message.success("操作成功！")
              this.$router.back()
            } else {
              this.$message.error(msg)
            }
          } catch (e) {
            return false
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    // 检查券信息是否正确
    checkCouponInfo() {
      const {total, time, limitedNum} = this.couponInfo
      return (total && time && limitedNum);
    },
    //
    checkActivityTime() {
      // 活动关联开始时间
      let activityStartTime = moment(this.form.activityTime[0]).format('YYYY-MM-DD HH:mm:ss')
      // 活动关联结束时间
      let activityEndTime = moment(this.form.activityTime[1]).format('YYYY-MM-DD HH:mm:ss')
      // 券领取开始时间
      let couponStartTime = moment(this.couponInfo.receiveCouponTime[0]).format('YYYY-MM-DD HH:mm:ss')
      // 券领取结束时间
      let couponEndTime = moment(this.couponInfo.receiveCouponTime[1]).format('YYYY-MM-DD HH:mm:ss')
      return (activityStartTime <= couponStartTime && activityEndTime >= couponEndTime);

    },
    beforeUploadImg(file) {
      // 校验格式
      this.commonlyUsedType = false;
      let index = file.name.lastIndexOf(".");
      let type = file.name.substring(index + 1, file.name.length);
      const isJpgOrPng = type === "png" || type === "jpg";
      if (!isJpgOrPng) {
        this.$message.error('图片格式只支持jpg，png 格式!');
        return Promise.reject();
      }
      // 校验大小
      const isLt2M = file.size / 1024 <= 1024;
      if (!isLt2M) {
        this.$message.error("图片大小不能超过1MB");
        return Promise.reject();
      }
      // 校验比例
      const isSize = new Promise(function (resolve, reject) {
        const URL = window.URL || window.webkitURL;
        const img = new Image();
        img.onload = function () {
          const valid = (img.width === img.height);
          valid ? resolve() : reject();
        };
        img.src = URL.createObjectURL(file);
      }).then(
        () => {
          return file;
        },
        () => {
          this.$message.error("图片不规范，请按要求上传");
          return Promise.reject();
        }
      );
      return isJpgOrPng && isLt2M && isSize;
    },
    // 上传展示图
    handleChangeImg({fileList}) {
      this.imgFileList = fileList;
      if (this.imgFileList[0].response) {
        this.form.picUrl = this.imgFileList[0].response.redirect_uri
      }
    },
    resetForm() {
      this.$refs.ruleForm.resetFields();
    },
    goBack() {
      this.$router.back()
    },
    // 活动类型change事件
    typeHandleChange(e) {
      console.log(e.target.value)
      const val = e.target.value;
      if (val === 1) {
        this.form.integralValue = ''
      }
    }
  },
  updated () {
    const _this = this
    const editor = new wangEditor(`#editer`)
    // 配置 onchange 回调函数，将数据同步到 vue 中
    editor.config.onchange = (newHtml) => {
      this.form.content = newHtml
    }
    editor.config.menus = [
      'fontName',
      'fontSize',
      'foreColor',
      'backColor',
      'underline',
      'italic',
      'bold',
      'justify',
      'splitLine',
      'undo',
      'redo',
      'list',
      'table',
      'image',
      'video',
    ]
    editor.config.uploadVideoServer = this.IMG_API + '/oss/files'
    editor.config.uploadImgServer = this.IMG_API + '/oss/files'
    editor.config.uploadImgShowBase64 = false
    editor.config.showLinkImg = false
    editor.config.showLinkVideo = false
    editor.config.uploadVideoMaxSize = 5 * 1024 * 1024
    editor.config.uploadImgMaxSize = 5 * 1024 * 1024
    editor.config.uploadImgAccept = ['jpg', 'jpeg', 'png', 'gif']
    editor.config.uploadImgMaxLength = 1 // 一次最多上传 1 个图
    // editor.config.uploadVideoAccept = ['mp4']
    editor.config.uploadFileName = 'file'
    editor.config.uploadVideoName = 'file'
    editor.config.uploadVideoTimeout = 60000
    editor.config.uploadImgTimeout = 60000
    editor.config.uploadVideoHooks = {
      // 上传视频之前
      before: function(xhr, editor, files) {
        console.log(xhr)
        console.log(editor)
        console.log(files)
      },
      // 视频上传并返回了结果，视频插入已成功
      success: function(xhr) {
        // this.$message.success('上传视频成功')
      },
      // 视频上传并返回了结果，但视频插入时出错了
      fail: function(xhr, editor, resData) {
        _this.$message.error('上传请求失败')
      },
      // 上传视频出错，一般为 http 请求的错误
      error: function(xhr, editor, resData) {
        _this.$message.error('上传请求失败')
      },
      // 上传视频超时
      timeout: function(xhr) {
        _this.$message.error('上传请求超时')
      },
      // 视频上传并返回了结果，想要自己把视频插入到编辑器中
      // 例如服务器端返回的不是 { errno: 0, data: { url : '.....'} } 这种格式，可使用 customInsert
      customInsert: function(insertVideoFn, result) {
        let videoHTML = '&nbsp;<video poster="' + result.redirect_uri + '?x-oss-process=video/snapshot,t_0,f_jpg,w_0,h_0,m_fast,ar_auto" src="' + result.redirect_uri +'" controls style="max-width:100%"></video>&nbsp;';
        editor.cmd.do('insertHTML', videoHTML);
        // result 即服务端返回的接口
        // insertVideoFn 可把视频插入到编辑器，传入视频 src ，执行函数即可
        // insertVideoFn(result.redirect_uri)
      }
    }
    editor.config.uploadImgHooks = {
      // 上传视频之前
      before: function(xhr) {
      },
      // 视频上传并返回了结果，视频插入已成功
      success: function(xhr) {
        // this.$message.success('上传视频成功')
      },
      // 视频上传并返回了结果，但视频插入时出错了
      fail: function(xhr, editor, resData) {
        _this.$message.error('上传请求失败')
      },
      // 上传视频出错，一般为 http 请求的错误
      error: function(xhr, editor, resData) {
        _this.$message.error('上传请求失败')
      },
      // 上传视频超时
      timeout: function(xhr) {
        _this.$message.error('上传请求超时')
      },
      // 视频上传并返回了结果，想要自己把视频插入到编辑器中
      // 例如服务器端返回的不是 { errno: 0, data: { url : '.....'} } 这种格式，可使用 customInsert
      customInsert: function(insertVideoFn, result) {
        // result 即服务端返回的接口
        // insertVideoFn 可把视频插入到编辑器，传入视频 src ，执行函数即可
        insertVideoFn(result.redirect_uri)
      }
    }
    editor.config.customAlert = function (s, t) {
      switch (t) {
        case 'success':
          _this.$message.success(s)
          break
        case 'info':
          _this.$message.info(s)
          break
        case 'warning':
          _this.$message.warning(s)
          break
        case 'error':
          _this.$message.error(s)
          break
        default:
          _this.$message.info(s)
          break
      }
    }
    editor.config.placeholder = '请输入活动详情'
    // 创建编辑器
    editor.create()
    if (this.isDisabled) {
      editor.disable()
    }
    this.editor = editor
    setTimeout(() => {
      this.editor.txt.html(this.form.content)
    },100);
  },
};
</script>

<style scoped>

</style>
